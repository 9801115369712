$breakpoint-xxl: 1600px;
$breakpoint-xxxl: 1920px;
$breakpoint-xxxxl: 2560px;

$color-link: #0266CF;
$color-sky: #06C9FF;
$color-sky-light: #D2F2FA;
$color-yellow: #FFFF00;
$color-orange: #FB7E00;
$color-purple: #4A2ECF;
$color-purple-1: #4A2ECF;
$color-purple-light: #4A2ECF;
$color-magenta: #f22c72;
$color-magenta-light: #FDDCFE;
//$color-red: #FF9674;
$color-red: #ff9f0a;
$color-red-light: #F1B2B1;
$color-red-dark: #D00;
$color-green: #58C79D;
$color-green-vivid: #35c95b;
$color-black: #32383e;
$color-secondary: map-get($theme-colors, "secondary");

$color-grey: #C3C6C6;

$color-grey-1: #5D5E5F;
$color-grey-2: #8F9091;
$color-grey-3: #C3C6C6;

$color-lightish-purple: #B947F9;
$color-algae-green: #25BB66;
$color-dusty-orange: #FB8B24;
$color-brownish-grey: #707070;
$color-dark-indigo: #191041;
$color-pinkish-red: #F51842;
$color-very-light-pink: #D8D8D8;
$color-pale-grey: #F3F4F8;
$color-timeline-selected-background: #4830D326;
$color-timeline-selected: #4830D3;
$color-table-selected-row: #DADDE8;

$color-table-patient-list-hover: #D6D6DB;
$color-table-patient-list-background: #F3F4F8;
$color-ice-blue: #EDEFEF;
$color-deep-sky-blue: #0088FF;
$color-brown-grey: #AAAAAA;
$color-deep-sky-blue-20: rgba(0, 136, 255, 0.2);
$color-pale-rose: #fcb9c6;

$page-bg: #F3F4F8;

$navbar-bg: #EAEBF1;
$navbar-height: 4.25rem;

$patient-bar-bg: #AEF0FF;

$card-bg: #FFFFFF;

$background-dark: $color-black;
$background-white: #F7F8F9;

$box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, .2);

$form-check-input-bg:                     $input-bg !default;
$form-check-input-border:                 var(--#{$prefix}border-width) solid $color-purple !default;
$form-check-input-border-radius:          .25em !default;
$form-check-radio-border-radius:          50% !default;
$form-check-input-focus-border:           $color-purple !default;
$form-check-input-focus-box-shadow:       $focus-ring-box-shadow !default;

$form-check-input-checked-color:          $color-purple !default;
$form-check-input-checked-bg-color:       $color-purple !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
