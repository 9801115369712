.navbar {
    height: $navbar-height;
    justify-content: space-between;
    padding: 0 1rem;
    color: white;
    background: $color-purple-1;

    @media (max-width: map-get($grid-breakpoints, md) - 1) {
        padding-right: 0;
    }

    &-brand {
        display: flex;
        align-items: center;
        padding: 0.25rem 0;
        color: inherit !important;

        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            font-size: 1rem;
        }
    }

    &-logo {
        margin-right: 1rem;

        img {
            display: block;
            max-height: 2rem;
            height: 100%;

            @media (min-width: map-get($grid-breakpoints, sm)) {
                max-height: 2.5rem;
            }
        }
    }

    &-toggler {
        width: $navbar-height;
        height: $navbar-height;
        padding: 0;
        border: none;
        outline: none !important;
    }

    &-nav {
        font-weight: bolder;
        @media (max-width: map-get($grid-breakpoints, md) - 1) {
            display: none;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            right: 0;
            padding: 1rem;
            background: $navbar-bg;

            &.show {
                display: block;
            }
        }
    }

    .nav {

        &-link {
            color: inherit !important;
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            transition: 0.2s ease-in;

            &:hover,
            &:focus {
                transform: translateY(-5px);
                transition: 0.2s ease-in;
            }

            &.nav-report {
                border: none;
                outline: none;
                cursor: pointer;
                padding: 6px 15px 6px 15px;
                border-radius: 10px;
                border: solid 1px white;
                background-color: $color-purple-1 !important;
                color: #fff !important;
                font-size: 16px;
                font-weight: 500;
            }
        }

    }
}